import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./on-search");
  require("./home-slider");
  require("./widget-handle");
  require("./building-markers");
  require("./compare");
  require("./commercial-widget");
  require("./sort");
  require("./select-all");
  require("./aside-form");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  if ($(".main-gallery").length > 0) {
    $(".main-gallery").simpleLightbox();
  }

  if ($(".link-gallery").length > 0) {
    $(".link-gallery").simpleLightbox();
  }


});


















;














// jQuery(function(){

//   jQuery("#form-test").submit(function(e) {

//       var name = jQuery("#form-field-name").val();

//       var surname = jQuery("#form-field-nazwisko").val();

//       var email = jQuery("#form-field-email").val();

//       var tel = jQuery("#form-field-telefon").val();

//       var message = jQuery("#form-field-message").val();

 

//       var formData = "<?xml version='1.0' encoding='utf-8' ?><root><key> b7409fb0-88d1-4bae-b834-bfaaf7648fd8</key><firstname>"+name+"</firstname><secondname></secondname><lastname>"+surname+"</lastname><email>"+email+"</email><phone>"+tel+"</phone><description></description><ylprofile></ylprofile><id_source></id_source><id_category></id_category><id_investment></id_investment><id_product></id_product><id_interestkind>5</id_interestkind><id_interestsource>4</id_interestsource><sourcedescription></sourcedescription><message>"+message+"</message><area></area><rooms></rooms><id_keeper></id_keeper></root>";

//       var apiUrl = 'https://dws1.eu/scripts/makeform.ashx';



//       var http = new XMLHttpRequest();

//       http.open("POST", apiUrl, true);

//       http.setRequestHeader("Content-Type", "text/plain; charset=UTF-8;");

     

//       http.onreadystatechange = function () {

//           if (this.readyState == XMLHttpRequest.DONE && this.status == 200) {

//               alert(this.responseText);

//           }

//       }

//       console.log(formData);

//       http.send(formData);

//       //alert(http.responseText);



//   });

// });











// var req = new XMLHttpRequest();
// req.open('GET', 'https://crm.gardenia-deweloper.pl:8082/getproducts.ashx?key=c3242bba-53ea-4f4b-8479-5038043d4bb5', true); /* Argument trzeci, wartość true, określa, że żądanie ma być asynchroniczne */
// req.onreadystatechange = function (aEvt) {
//   if (req.readyState == 4) {
//      if(req.status == 200)
//       console.log(req.responseText);
//      else
//       console.log("Błąd podczas ładowania strony\n");
//   }
// };
// req.send(null);


// fetch('https://crm.gardenia-deweloper.pl:8082/getproducts.ashx?key=c3242bba-53ea-4f4b-8479-5038043d4bb5')
// .then(response => {
//   response.text().then(text => {
//     // handle response content
//     console.log(text);
//     })
//   })

