import {
  getCookie,
  setCookie,
  deleteCookie
} from "./cookie-bar";



const buildings = [...document.querySelectorAll(".widget-building")];
const markers = [...document.querySelectorAll(".widget-marker")];

if(buildings){
    buildings.forEach(function(building){
        const buildingId = building.dataset.building;
        const marker = markers.find(function(item) {
            return item.dataset.building == buildingId;
          })
        
        $(building).hover(
            function () {
              $(marker).addClass('active');
            }, 
            function () {
              $(marker).removeClass('active');
            }
            );
    })
}

const prevBtn = document.getElementById("prev-page");

if(prevBtn){
  prevBtn.addEventListener("click", function(e){

    const buildingId = e.target.dataset.id;
    const floorId = e.target.dataset.floor;
    const buildingSymbol = e.target.dataset.building;
    const premisesType = e.target.dataset.premises;

    setCookie("building-id", buildingId);
    setCookie("building-symbol", buildingSymbol);
    setCookie("floor-id", floorId);

    if(premisesType == "uzytkowy"){
      window.location.href = '/pogodno/lokale-uslugowe'; 
    }else{
      window.location.href = '/pogodno/mieszkania';       
    }

     
    

    
  });
}

// const widget = document.getElementById("widget-svg-step1");

// if(widget){

//     const buildingId = getCookie('building-id');
//     const buildingSymbol = getCookie('building-symbol');
//     const floorId = getCookie('floor-id');

//     if(buildingSymbol && floorId && buildingId){

//       const modal = document.getElementById("widget-modal-step1");
//       modal.classList.add("is-open");
  
//       // const building = $(modal).find(".step1-building[data-building='" + buildingSymbol + "']");
//       // $(building).addClass("active");

//       // const modalStep2 = building.find(".widget-modal-step2");
//       // $(modalStep2).addClass("is-open");

//       // const floor = modalStep2.find(".floor[data-floor='" + floorId +"']");
//       // $(floor).addClass("active");

//       // deleteCookie('building-symbol');
//       // deleteCookie('floor-id');
//       activeBuilding(true, buildingSymbol, buildingId);

      


//     }


// }