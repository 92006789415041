import {
  getCookie,
  setCookie,
  deleteCookie
} from "./cookie-bar";


const widget = document.getElementById("widget-svg-step1");

function setNextBuilding(buildingId){

  const nextBuildingStep1 = document.getElementById("modal-next-building");

  switch(buildingId){
    case '1A':
      nextBuildingStep1.textContent = "Pokaż budynek 1B"; 
      nextBuildingStep1.dataset.building = "1B"
      break;
    case '1B':
      nextBuildingStep1.textContent = "Pokaż budynek 1A";
      nextBuildingStep1.dataset.building = "1A"
      break;
    case '2A':
      nextBuildingStep1.textContent = "Pokaż budynek 2B";
      nextBuildingStep1.dataset.building = "2B"
      break;
    case '2B':
      nextBuildingStep1.textContent = "Pokaż budynek 2A";
      nextBuildingStep1.dataset.building = "2A"
      break;
    case '3A':
      nextBuildingStep1.textContent = "Pokaż budynek 3B";
      nextBuildingStep1.dataset.building = "3B"
      break;
    case '3B':
      nextBuildingStep1.textContent = "Pokaż budynek 3A";
      nextBuildingStep1.dataset.building = "3A"
      break;
    case '4A':
      nextBuildingStep1.textContent = "Pokaż budynek 4B";
      nextBuildingStep1.dataset.building = "4B"
      break;
    case '4B':
      nextBuildingStep1.textContent = "Pokaż budynek 4A";
      nextBuildingStep1.dataset.building = "4A"
      break;
  }

}


// function closeStep2(elem) {

  
//   elem.addEventListener("click", function(e) {
//     e.preventDefault();
//     $(".widget-modal-step2").removeClass("is-open");
//   });

// }


if (widget) {


  $.request("flatsList::onGetFlatsJson", {
    success: function(data) {
      const obj = JSON.parse(data.result);
      //convert object data to array
      function* values(obj) {
        for (let prop of Object.keys(obj)) yield obj[prop];
      }
      let cmsFlats = Array.from(values(obj));

      const buildings = [...document.getElementsByClassName("widget-building")];
      const buildingsStep1 = [
        ...document.getElementsByClassName("step1-building"),
      ];
      const floorsStep1 = [...document.getElementsByClassName("step1-floor")];

      const siteWrapper = document.getElementsByClassName("site-wrapper")[0];

      //floor tooltip
      const tooltip = document.getElementById("widget-tooltip");
      const tooltipCounter = document.getElementById("widget-tooltip-counter");
      const apartmentType = document.getElementById("apartment-type");
      siteWrapper.appendChild(tooltip);

      //modal step1
      const modalStep1 = document.getElementById("widget-modal-step1");
      siteWrapper.appendChild(modalStep1);

      //modal close handle
      closeStep1();

      //actions on building click
      const modalHeadStep1 = document.getElementById("widget-modal-head-step1");

      //modal next building
      const nextBuildingStep1 = document.getElementById("modal-next-building");
      nextBuildingStep1.addEventListener("click", function(e) {
        
        const buildingIdBtn = e.target.dataset.building;
        
        buildingsStep1.forEach(function(item){
            item.classList.remove("active");
        });

        buildingsStep1.forEach(function(item){
            if(item.dataset.building === buildingIdBtn){
              item.classList.add("active");
            }
        });

        modalHeadStep1.textContent = buildingIdBtn;
        setNextBuilding(buildingIdBtn);        

      });

      function closeStep1(){
        const modalCloseStep1 = document.getElementById("modal-close-step1");
      
        modalCloseStep1.addEventListener("click", function(e) {
          e.preventDefault();
          modalStep1.classList.remove("is-open");
          document.body.classList.remove("modal-open");
          buildingsStep1.forEach((buildingStep1) => {
            buildingStep1.classList.remove("active");
          });
        });
      
      }


      function openModalStep2(selectedFloorId, buildingStep1){

        const step2Floors = [...$(buildingStep1).find(".floor")];

        const selectedFloor = step2Floors.find(
          (item) => item.dataset.floor === selectedFloorId
        );

        const modalStep2 = [
          ...$(buildingStep1).find(".widget-modal-step2"),
        ][0];

        const tooltipStep2 = [
          ...$(buildingStep1).find(".widget-tooltip-step2"),
        ][0];

        const flatsStep2 = [...$(selectedFloor).find(".flat")];

        const counterApartment = $(buildingStep1).find(
          ".counter-apartment"
        )[0];

        const counterRooms = $(buildingStep1).find(
          ".counter-rooms"
        )[0];

        const counterSize = $(buildingStep1).find(
          ".counter-size"
        )[0];

        const previewAvailability = $(buildingStep1).find(
          ".preview-availability"
        )[0];

        const closeStep2 = $(buildingStep1).find(
          ".modal-close-step2"
        )[0];

        //set floor name
        let floorName;

        if (selectedFloorId == 1) {
          floorName = "Parter";
        } else if (selectedFloorId == 2) {
          floorName = "Piętro 1";
        } else if (selectedFloorId == 3) {
          floorName = "Piętro 2";
        } else if (selectedFloorId == 4) {
          floorName = "Piętro 3";
        } else if (selectedFloorId == 5) {
          floorName = "Piętro -1";
        }


        $(buildingStep1)
          .find("#widget-modal-head-step2")
          .text(floorName);

        if(buildingStep1.classList.contains("active")){
          $(buildingStep1).find(".widget-modal-head-building-step2").text(`Budynek ${buildingStep1.dataset.building} - `);
        }


        //step2 flat tooltip

        flatsStep2.forEach((flat) => {
          const flatId = flat.id;

          const findFlat = cmsFlats.find(
            (cmsFlat) => cmsFlat.symbol === flatId
          );

          if (findFlat) {
            flat.addEventListener("click", function(e) {
              e.preventDefault();
              if (findFlat.status === "wolne") {
                window.location = `/pogodno/mieszkania/${findFlat.id}/${findFlat.slug}`;
              }
            });
            flat.addEventListener("mouseenter", function() {
              tooltipStep2.style.transform = "scale(1)";
              tooltipStep2.style.opacity = "1";

              if (findFlat.name) {
                counterApartment.textContent = findFlat.symbol;
                counterApartment.parentElement.style.display =
                  "block";
              } else {
                counterApartment.parentElement.style.display =
                  "none";
              }

              if (findFlat.rooms_number) {
                counterRooms.textContent = findFlat.rooms_number;
                counterRooms.parentElement.style.display = "block";
              } else {
                counterRooms.parentElement.style.display = "none";
              }

              if (findFlat.area) {
                counterSize.textContent = findFlat.area.toFixed(2) + " \u33A1";
                counterSize.parentElement.style.display = "block";
              } else {
                counterSize.parentElement.style.display = "none";
              }

              if (findFlat.status) {
                previewAvailability.textContent = findFlat.status;
                previewAvailability.parentElement.style.display =
                  "block";
              } else {
                previewAvailability.parentElement.style.display =
                  "none";
              }
            });
            flat.addEventListener("mousemove", function(e) {
              const left = parseInt(e.clientX) + 30 + "px";
              const top = parseInt(e.clientY) + 30 + "px";
              tooltipStep2.style.left = left;
              tooltipStep2.style.top = top;
            });
            flat.addEventListener("mouseleave", function() {
              tooltipStep2.style.opacity = "0";
              tooltipStep2.style.transform = "scale(0)";
            });
          }
        });

        //set flats status
        flatsStep2.forEach((flat) => {
          const flatId = flat.id;
          const findFlat = cmsFlats.find(
            (cmsFlat) => cmsFlat.symbol === flatId
          );
          if (findFlat) {
            if (findFlat.status === "wolne") {
              flat.classList.add("widget__flat-status--green");
            } else if (findFlat.status === "zarezerwowane") {
              flat.classList.add("widget__flat-status--yellow");
            } else {
              flat.classList.add("widget__flat-status--red");
            }
          } else {
            flat.classList.add("widget__flat-status--red");
          }
        });

        //open step2

        modalStep2.classList.add("is-open");
        step2Floors.forEach(function(element) {
          if (element.dataset.floor === selectedFloorId) {
            element.classList.add("active");
          } else {
            element.classList.remove("active");
          }
        });

        //close step2

        closeStep2.addEventListener("click", function(e) {
          e.preventDefault();
          $(".widget-modal-step2").removeClass("is-open");
        });

      }


      function activeBuilding(buildingEnable, modalBuildingSymbol, modalBuildingId, modalSelectedFloorId = false){

        
        if (buildingEnable == "true") {

          
          modalHeadStep1.textContent = modalBuildingSymbol;


          setNextBuilding(modalBuildingSymbol);

          buildingsStep1.forEach(function(buildingStep1) {


            
            if (
              buildingStep1.dataset.building === modalBuildingSymbol
            ) {
              
              buildingStep1.classList.add("active");
            }
            
              const floorsInBuilding = [
                ...$(buildingsStep1).find(".step1-floor"),
              ];

              floorsInBuilding.forEach(function(floorStep1) {
                const flatsOnFloor = cmsFlats.filter(
                  (flat) =>
                    flat.building_id === parseInt(modalBuildingId) &&
                    flat.floor_id === parseInt(floorStep1.dataset.floor) &&
                    flat.status === "wolne"
                );



                floorStep1.addEventListener("mouseenter", function() {
                  tooltip.style.transform = "scale(1)";
                  tooltip.style.opacity = "1";
                  if(((modalBuildingId == 1) || (modalBuildingId == 2)) && ((floorStep1.dataset.floor) == 1 || (floorStep1.dataset.floor == 5))){
                    tooltipCounter.textContent = "";
                    apartmentType.textContent = "Zobacz lokale usługowe"
                  }else{
                    tooltipCounter.textContent = flatsOnFloor.length;
                    apartmentType.textContent = "ilość dostępnych mieszkań:"
                  }
                });
                floorStep1.addEventListener("mousemove", function(e) {
                  const left = parseInt(e.pageX) + 30 + "px";
                  const top = parseInt(e.pageY) + 30 + "px";

                  tooltip.style.left = left;
                  tooltip.style.top = top;
                });
                floorStep1.addEventListener("mouseleave", function() {
                  tooltip.style.opacity = "0";
                  tooltip.style.transform = "scale(0)";
                });

                floorStep1.addEventListener("click", function(e) {
                  e.preventDefault();

                  const selectedFloorId = e.target.dataset.floor;

                  if(((floorStep1.dataset.floor) == 1 || (floorStep1.dataset.floor == 5)) && ((modalBuildingId == 1) || (modalBuildingId == 2))){

                    window.location = '/pogodno/lokale-uslugowe'; 
                  
                  }else{
                    openModalStep2(selectedFloorId, buildingStep1);
                  }
                  
 
                });
                
              });

              if(modalSelectedFloorId){

                  openModalStep2(modalSelectedFloorId, buildingStep1);
                

              }

          });

          modalStep1.classList.toggle("is-open");
          document.body.classList.toggle("modal-open");
        }


      }


      buildings.forEach((building) => {
        const modalBuildingSymbol = building.dataset.building;
        const modalBuildingId = building.dataset.id;

        building.addEventListener("click", function(e) {
          e.preventDefault();

          

          const buildingEnable = building.dataset.enable;


          
          activeBuilding(buildingEnable, modalBuildingSymbol, modalBuildingId);


        });


      });


      const buildingId = getCookie('building-id');
      const buildingSymbol = getCookie('building-symbol');
      const floorId = getCookie('floor-id');
  
      if(buildingSymbol && floorId && buildingId){
  
        const modal = document.getElementById("widget-modal-step1");

  
        deleteCookie('building-id');
        deleteCookie('building-symbol');
        deleteCookie('floor-id');
        activeBuilding("true", buildingSymbol, buildingId, floorId);
  
      }


    },
  });
}
