$("#top-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dots: false,
    autoplay: true,
    focusOnSelect: false,
    focusOnChange: false,
    infinite: true,
    adaptiveHeight: true,
    speed: 2000,
    autoplaySpeed: 3000,
  });


  $("#section4-slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    infinite: true,
    speed: 1500,
    nextArrow: $("#next"),
    prevArrow: $("#prev"),
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
