const forms = [...document.querySelectorAll("form")];

if(forms){
    forms.forEach(form => {

        
        const checkAll = $(form).find(".check-all"); 
        
        
        if(checkAll){

                checkAll.on("click", function(){
                    const allCheckbox = $(form).find(".formCheckbox");
                    $(allCheckbox).not(this).prop('checked', this.checked)
                    // allCheckbox.each(item=>{
                    //     $(item).prop("checked", true);
                    // })
                })
        //     checkAll.addEventListener('change', function(e) {
                
        //         const allCheckbox = $(form).find(".formCheckbox");
        
        //         allCheckbox.forEach(function(item){
        //             item.checked = e.target.checked;
        //         })
        
        //     }
        //     );
        
        
        }

    })
}

