const noResults = document.querySelector(".flat-list-no-results");

if (!noResults && document.querySelector(".flat-list-page--scroll")) {
  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $("#flatList").offset().top,
    },
    1500
  );
}
