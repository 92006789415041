const asideBtn = document.getElementById("aside-form-btn");

if(asideBtn){
    const asideForm = document.getElementById("aside-form");
    asideBtn.addEventListener("click", function(){
        asideForm.classList.toggle("active");
    })

}

