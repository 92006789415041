/* mobile menu */

$(".header__hamburger").click(function() {
  $(".header__hamburger-inner").toggleClass("header__hamburger-inner--open");
  $(".header__hamburger-label").toggleClass("header__hamburger-label--open");
  $("html, body").toggleClass("blocked");
  $(".header__nav").toggleClass("header__nav--visible");
});


// const topSection = document.getElementById("top-section");

// if(topSection){

//     const headerNav = document.getElementById("header-nav");
//     const topSearch = document.getElementById("top-search");
//     console.log(headerNav)
//     headerNav.style.height = topSection.offsetHeight + 'px';



// }