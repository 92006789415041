import {
    getCookie,
    setCookie,
    deleteCookie
  } from "./cookie-bar";




const toCompare = [...document.querySelectorAll(".to-compare")];
const compareCounter = document.getElementById("compare");
const removeAll = document.getElementById("compare-remove-all");
const comparePage = document.querySelector(".compare-page");



function setFlatsInForm(){

    const selectedFlats = [];
    const selectedFlatsApiId = []

    const flatItems = [...document.getElementsByClassName("flat-item")]; 
    if(flatItems){

        flatItems.forEach(function(item) {
            selectedFlats.push(item.dataset.flat);
            selectedFlatsApiId.push(item.dataset.api);
        })
        

        document.getElementById("flatname").value = selectedFlats.join(", ");
        document.getElementById("flatsSymbol").value = selectedFlatsApiId.join(", ");

    }

}

//if compare page
if(comparePage){
    setFlatsInForm();
}


//set counter
if(compareCounter){

    const flatsInCompare = getCookie('flats');

    if(flatsInCompare){

        const flatsArray = JSON.parse(flatsInCompare);
        compareCounter.textContent = flatsArray.length;
    } else{
        compareCounter.textContent = 0;
    }

}


//add to compare btn
if(toCompare.length){

    function chceckSelectedOnLoad(){

        const flatsInCompare = getCookie('flats');

        if(flatsInCompare){

            const flatsArray = JSON.parse(flatsInCompare);

            toCompare.forEach(function(item){
                const flatId = parseInt(item.dataset.id);
                if(flatsArray.includes(flatId)){
                    item.classList.add("compare-remove");
                }
            })

        }

    }

    chceckSelectedOnLoad();

    toCompare.forEach(function(item){

        const flatId = parseInt(item.dataset.id);

        item.addEventListener("click", function(){ 
            
            const flatsInCompare = getCookie('flats');

            if(flatsInCompare){
                
                const flatsArray = JSON.parse(flatsInCompare);

                let newArray;

                if(flatsArray.includes(flatId)){
                    if(comparePage){
                        $(item).closest(".flat-list__item").fadeOut(600, function() { $(this).remove(); })
                        setTimeout(function(){
                            setFlatsInForm();
                        },700)
                    }else{
                        item.classList.remove("compare-remove");
                    }
                    newArray = flatsArray.filter(function(flat){
                        return flat != flatId
                    })
                    if(newArray.length == 0){
                        deleteCookie('flats');
                        compareCounter.textContent = 0;
                    }else{
                        setCookie('flats', JSON.stringify(newArray), 30);
                        compareCounter.textContent = newArray.length;

                    }

                }else{
                    item.classList.add("compare-remove");
                    newArray = [...flatsArray, flatId];
                    setCookie('flats', JSON.stringify(newArray), 30);
                    compareCounter.textContent = newArray.length;
                }


            }else{
                item.classList.add("compare-remove");
                setCookie('flats', JSON.stringify([flatId]), 30);
                compareCounter.textContent = 1;
            }

            compareCounter.classList.add("shake");
            setTimeout(function(){
                compareCounter.classList.remove("shake");
            },1000)

        })
    })

    
}


//remove all items
if(removeAll){

    removeAll.addEventListener("click", function() {

        const flatsInCompare = getCookie('flats');

        if(flatsInCompare){
    
            deleteCookie('flats');
            compareCounter.textContent = 0;
            compareCounter.classList.add("shake");
            setTimeout(function(){
                compareCounter.classList.remove("shake");
            },1000)
    
            $(".flat-list__item").fadeOut(600, function() { $(this).remove(); });
            $(".remove-all").fadeOut(600, function() { $(this).remove(); });
            $("#flat-list-head").after('<span class="flat-list__no-results">Brak wybranych lokali</span>');

            setTimeout(function(){
                setFlatsInForm();
            },700)

            
            
        }

    });

}




//send 




// jQuery(function(){

//     jQuery("#form-test").submit(function(e) {
//         e.preventDefault();

//         var name = jQuery("#form-field-name").val();

//         var surname = jQuery("#form-field-nazwisko").val();

//         var email = jQuery("#form-field-email").val();

//         var tel = jQuery("#form-field-telefon").val();

//         var message = jQuery("#form-field-message").val();

   

//         var formData = "<?xml version='1.0' encoding='utf-8' ?><root><key> b7409fb0-88d1-4bae-b834-bfaaf7648fd8</key><firstname>"+name+"</firstname><secondname></secondname><lastname>"+surname+"</lastname><email>"+email+"</email><phone>"+tel+"</phone><description></description><ylprofile></ylprofile><id_source></id_source><id_category></id_category><id_investment></id_investment><id_product></id_product><id_interestkind>5</id_interestkind><id_interestsource>4</id_interestsource><sourcedescription></sourcedescription><message>"+message+"</message><area></area><rooms></rooms><id_keeper></id_keeper></root>";

//         var apiUrl = 'https://dws1.eu/scripts/makeform.ashx';

 

//         var http = new XMLHttpRequest();

//         http.open("POST", apiUrl, true);

//         http.setRequestHeader("Content-Type", "text/plain; charset=UTF-8;");

       

//         http.onreadystatechange = function () {

//             if (this.readyState == XMLHttpRequest.DONE && this.status == 200) {

//                 alert(this.responseText);

//             }

//         }

//         console.log(formData);

//         http.send(formData);

//         //alert(http.responseText);

 

//     });

// });










// const getDataApi = async () => {

//     const response = await fetch(
//       "https://crm.gardenia-deweloper.pl:8082/getproducts.ashx?key=c3242bba-53ea-4f4b-8479-5038043d4bb5"
//     );
//     const str = await response.text();
//     const data = new window.DOMParser().parseFromString(str, "text/xml");
    
// };


// const getData = () => {

//     $.request("flatsCompare::onGetFlatsJson", {

//         success: function(data) {
//             const obj = JSON.parse(data.result);
//             //convert object data to array
//             function* values(obj) {
//                 for (let prop of Object.keys(obj)) yield obj[prop];
//             }
//             let cmsFlats = Array.from(values(obj));
//             console.log(cmsFlats)
//             $.ajax("https://crm.gardenia-deweloper.pl:8082/getproducts.ashx?key=c3242bba-53ea-4f4b-8479-5038043d4bb5", {
//                 success: function(data2){

//                     // const obj = data2.text();
//                     // const data2Parse = new window.DOMParser().parseFromString(obj, "text/xml");
//                     console.log(data2)
//                 }
//             })

//         }

//     });


// }




//   getData();


